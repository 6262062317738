/*

Add:
- Contact page with mailjet API
- link to all music downloads
- blog section with 



To do:
  - Incorporate React Router v6
  - Add nav section
  - Add About/contact page (contact contingent on API)
  - Add shop page/learn to use shopify/hydrogen

*/

import React from "react"
import { useState } from "react";
import './App.css';

function App() {
  const [sectionExpanded, setSectionExpanded] = useState(false);

  function toggleSectionExpanded() {
    if(sectionExpanded){
      setSectionExpanded(false)
    } else {
      setSectionExpanded(true)
    }
  }

  return (
    <div className="App">
      
      <div className='container'>
        <nav>
          <h1>SBR7009</h1>
        </nav>
        
        <div className="featured-content-container">
          
          <div className="section-container newest-container">
            <h2 id="newest">Newest Release</h2>
            <iframe  src="https://open.spotify.com/embed/album/1IYWN7QQR2rS8ZqMcxHAH9?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
          </div>

          <div className="section-container music-video-container">
            <h2>Music video</h2>
            <iframe title='Nasea YouTube video' width="560" height="315" src="https://www.youtube.com/embed/8FkyeebbMac?si=z4NWKDuSy5Q-ODSv"  frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
          </div>

        </div>
        
        
        

        <div className="section-container section-collapsed" style={{height: (sectionExpanded ? '' : '550px')}}>
        
          <h2>Cat Dog Bear</h2>
          <div className='music-section'>
            <iframe title="AnimalBeatsVol1" src="https://open.spotify.com/embed/album/4eMs0FKIQmtWYHIhN7wFG6?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <iframe title="Worth" src="https://open.spotify.com/embed/album/0YJJAwaZYL5kK9FQS0uf0x?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <iframe title="ReturnOfSquishy" src="https://open.spotify.com/embed/album/2MvK4SQyrcQbMJSnp9o7bB?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <iframe title='ThisWasntSupposed' src="https://open.spotify.com/embed/album/1P7iGJYpuQQBwMpVZMwbFy?utm_source=generator&theme=0" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <iframe title='CatvsDogvsBear' src="https://open.spotify.com/embed/album/7HPqhJ0y1Kqrkyxi7KtAmi?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <iframe title='BackToBasics' src="https://open.spotify.com/embed/album/4CtxgSj6UehGUpy0HK2ATR?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <iframe title='PS' src="https://open.spotify.com/embed/album/3BGb4o5C6e6ujmhSkdrSPE?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <iframe title="ICanOnlyFakeMusic" src="https://open.spotify.com/embed/album/4oEtPyNswTj5doGwJblDJn?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
          </div>
          <div className="section-expand-arrow" onClick={() => toggleSectionExpanded()}>
            <svg style={{width: '32px', height: '20px', paddingTop: (sectionExpanded ? '' : '2px')}}>
              <path 
                  d='M 19 3 C 17 1 15 1 13 3 L 1 16 C 0 18 2 20 4 19 L 16 6 L 28 19 C 30 20 32 18 31 16'
                  viewBox='0 0 32 20'
                  style={{
                      fill: '#fff', 
                      transform:  (sectionExpanded ? '' : 'rotate(180deg)'),
                      transformOrigin: 'center center'
                  }}></path>
              </svg>
          </div>
        </div>
        
        <div className="section-container soundcloud-links-container">
        <div id="soundcloud-logo-container">
          <div className="soundcloud-logo" alt="The SoundCloud logo: a cloud icon with the left half reselmbling an audio waveform (parallel, upright bars), and the right half filled in normally."></div>
        </div>
          <h2>SoundCloud links</h2>
          <div className='soundcloud'>
            <a className='soundcloud-links' href='https://soundcloud.com/user-851904588?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing'>Cat Dog Bear SoundCloud page</a>
            <a className='soundcloud-links' href='https://soundcloud.com/user-851904588?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing'>Sued By Ramen SoundCloud page</a>
          </div>

        </div>
      </div>

      <footer>
        <div id='footer-text'>Site made by <a href='https://github.com/Guzzler829'>Guzzler829</a>, 2023-2024</div>
      </footer>
      {/* Add later: apple music and amazon links */}
    </div>
  );
}

export default App;
